export enum LocationType {
  Undefined = 0,
  Intersection = 1,
  Midblock = 2,
  Street = 3,
}

export enum StreetDirection {
  None = 1,
  NorthSouth = 2,
  EastWest = 3,
}

export enum SectionType {
  Undefined = 0,
  FourLegIntersection = 1,
  TIntersection = 2,
  OneWayRoad = 3,
  TwoWayRoad = 4,
}

export enum ArmadilloVehicleType {
  Small = 1,
  Medium = 2,
  Large = 4,
}

export enum SectionDetailType {
  FourLegIntersection = 1,
  NorthTIntersection = 2,
  EastTIntersection = 3,
  SouthTIntersection = 4,
  WestTIntersection = 5,
  NorthOneWayRoad = 6,
  EastOneWayRoad = 7,
  SouthOneWayRoad = 8,
  WestOneWayRoad = 9,
  NorthSouthTwoWayRoad = 10,
  EastWestTwoWayRoad = 11,
}

export enum DataSourceType {
  Excel = 1,
  ArcGIS = 2,
}

export enum CastType {
  Int = 1,
  Double = 2,
  Float = 3,
  Decimal = 4,
}

export enum MatchByType {
  Id = 1,
  Name = 2,
  GeoId = 3,
}
export enum LocationPickerMode {
  None = 0,
  TextBox = 1,
  Button = 2,
}

export enum InfraStructureDetailsComponentMode {
  Details = 1,
  ApproachDetails = 2,
}
export enum MapUpdateStatus {
  OK = 0,
  NeedReview = 1
}


export enum MMSResStatus
{
    Calculated = 1,
    AADTNotAvailable = 2,
    PostedSpeedNotAvailable = 4
}