import { Button, DateBox, Form, SelectBox, TagBox, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import CollapsibleCard from "../../../../components/collapsibleCard/collapsibleCard";
import { NameValue } from "../../../../types/general/generalTypes";
import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { StudyCategory, StudyStatus, StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";

const Export = () => {
    
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const { generalSetting } = useClientSetting();
    const [lstLocationType, setLstLocationType] = useState<NameValue[]>([])
    const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
    const [lstStudyCategory, setLstStudyCategory] = useState<NameValue[]>([]);
    const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                setLstLocationType(Enum2Array(LocationType));
                setLstStudyType(Enum2Array(StudyType));
                setLstStudyCategory(Enum2Array(StudyCategory));
                setLstStudyStatus(Enum2Array(StudyStatus));
            } catch (ex) {
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`copySetting ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <h2 className={'content-block'}>{t('export')}</h2>
                <div className={'content-block'}>
                    <div className={'dx-card'} style={{ padding: "0.5rem 1rem"}}>
                        <CollapsibleCard title={t('trafficIntegration')} isOpen={true}>
                            <Form colCount={3} className='popupFields'>
                                {/* <SimpleItem colSpan={1}>
                                    <div style={{fontWeight: "bolder", marginTop:"1rem"}}>Multi Study Picker Component</div>
                                </SimpleItem> */}
                                {/* <SimpleItem colSpan={1}>
                                    <div style={{fontWeight: "bolder", marginTop:"1rem"}}>Multi geoId Picker Component</div>
                                </SimpleItem> */}
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("division")}
                                        // dataSource={SortObjectByPropName(, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        // value={}
                                        // onValueChange={}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("municipality")}
                                        // dataSource={SortObjectByPropName(, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        // value={}
                                        // onValueChange={}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("jurisdiction")}
                                        // dataSource={SortObjectByPropName(, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        // value={}
                                        // onValueChange={}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                {/* <SimpleItem colSpan={1}>
                                    <div style={{fontWeight: "bolder", marginTop:"1rem"}}>Multi location Picker Component</div>
                                </SimpleItem> */}
                                <SimpleItem colSpan={1}>
                                    <DateBox
                                       label={t("fromDate")}
                                       type="date"
                                    //    value={}
                                    //    onValueChange={}
                                       pickerType="calendar"
                                       placeholder={generalSetting?.dateFormat}
                                       displayFormat={generalSetting?.dateFormat}
                                       useMaskBehavior={true}
                                       openOnFieldClick={true}
                                       showClearButton={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <DateBox
                                       label={t("toDate")}
                                       type="date"
                                    //    value={}
                                    //    onValueChange={}
                                       pickerType="calendar"
                                       placeholder={generalSetting?.dateFormat}
                                       displayFormat={generalSetting?.dateFormat}
                                       useMaskBehavior={true}
                                       openOnFieldClick={true}
                                       showClearButton={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                        placeholder=""
                                        label={t('locationType')}
                                        valueExpr="id"
                                        displayExpr="name"
                                        labelMode='static'
                                        // value={}
                                        // onValueChange={}
                                        items={SortObjectByPropName(lstLocationType, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("studyType")}
                                      labelMode="floating"
                                    //   value={}
                                    //   onValueChange={}
                                      items={SortObjectByPropName(lstStudyType, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("category")}
                                      labelMode="floating"
                                    //   value={}
                                    //   onValueChange={}
                                      items={SortObjectByPropName(lstStudyCategory, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("status")}
                                      labelMode="floating"
                                    //   value={}
                                    //   onValueChange={}
                                      items={SortObjectByPropName(lstStudyStatus, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                            </Form>
                            <Button
                            style={{marginTop:"0.5rem"}}
                              className='tes-modal-btn-add rightColumn'
                            //   onClick={onExport}
                              text={t('export')}
                            />
                        </CollapsibleCard>
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
};

export default Export;
